<template>
  <div>
      <LiefengContent>
        <template v-slot:title>家庭管理</template>
        <template v-slot:toolsbarRight>
              <Form class="search" :label-width="100" :inline="true">
                    <FormItem label="家庭名称">
                    <Input v-model="search.name" placeholder="填输入家庭名称"></Input>
                    </FormItem>
                    <FormItem label="家庭地址">
                    <Input v-model="search.address" placeholder="填输入家庭地址"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search-outline" style="margin-left: 10px; " @click="searchBtn">搜索</Button>
                    <Button type="success" icon="ios-refresh" style="margin-left: 10px;" @click="reset">重置</Button>
                    <Button type="info" icon="ios-add" style="margin-left: 10px;" @click="addHome">新增</Button>
              </Form>
        </template>
        <!--身体部分-->
        <template v-slot:contentArea>
             <div class="container">
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[50, 200, 500, 1000]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
             </div>
        <LiefengModal  :fullscreen="true" :title="isEdit?'修改家庭':'新增家庭'" @input="addModalFn" :value="addModal.status">
          <template #contentarea>
              <Addgroup v-if="isShow" :memberFromScope="memberFromScope" :formData="formData" :isEdit="isEdit" :cityOper="cityOper" :localStree="localStree"></Addgroup>
          </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="addModal.tip = true;addModalFn(false)">取消</Button>
            <Button type="primary" @click="addSave">确定</Button>
          </template>
        </LiefengModal>
        <LiefengModal :fullscreen="true" title="查看家庭" @input="detailModalFn" :value="detailModal.status">
            <template #contentarea>
                <div class="equiment-title">基本信息</div>
                <div class="table-div">
                    <div class="item"><span>家庭名称：</span>{{detailMessage.familyName || '未知'}}</div>
                    <div class="item"><span>家庭地址：</span>{{'未知'}}</div>
                    <div class="item"><span>家庭详细地址：</span>{{detailMessage.addrIncomplete ||'未知'}}</div>
                    <div class="item"><span>家庭邀请码：</span>{{detailMessage.inviteCode || '未知'}}</div>
                    <div class="item"><span>经度：</span>{{detailMessage.longitude || '暂无'}}</div>
                    <div class="item"><span>纬度：</span>{{detailMessage.latitude || '暂无'}}</div>
                </div>
                <div class="equiment-title">家庭人员</div>
                <div>
                     <Table stripe border  :columns="detailColumns" :data="detailMessage.memberList"></Table>
                </div>
            </template>
            <template #toolsbar>
                 <Button type="primary" @click="detailModalFn(false)">确定</Button>
            </template>
        </LiefengModal>
        </template>
      </LiefengContent>
  </div>
</template>

<script>
  //@route("/homecustomindex")
  import LiefengContent from "@/components/LiefengContent";
  import LiefengTable from "@/components/LiefengTable";
  import LiefengModal from "@/components/LiefengModal";
  import Addgroup from "@/views/jujiabuild/homecustom/childrens/addgroup";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengContent,LiefengTable,LiefengModal,Addgroup},
    data() {
      //这里存放数据
      return {
          talbeColumns:[
              {
                title: "家庭名称",
                key: "familyName",
                minWidth: 200,
                align: "center"
            },
            {
                title: "家庭住址",
                key: "addrIncomplete",
                minWidth: 200,
                align: "center"
            },
            {
                title: "家庭详细地址",
                key: "addrComplete",
                minWidth: 200,
                align: "center"
            },
            {
                title: "邀请码",
                key: "inviteCode",
                minWidth: 200,
                align: "center"
            },
            {
                title: "经度",
                key: "longitude",
                minWidth: 200,
                align: "center"
            },
            {
                title: "纬度",
                key: "latitude",
                minWidth: 200,
                align: "center"
            },
            {
                title:'操作',
                align:'center',
                minWidth:200,
                render:(h,params) =>{
                     return h(
                        "div",
                        {
                            style: {
                            textAlign: "left",
                            display:'flex',
                            justifyContent:'center'
                            },
                        },
                        [
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                      this.familyId = params.row.familyId
                                      this.memberFromScope = "";
                                      this.getDetail(params.row.familyId)
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "修改" //1001
                            ),       
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.isTure = true
                                        this.getCheck(params.row.familyId)
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "查看" //1001
                            ),
                            h('Button',{
                                attrs:{
                                    type:'error',
                                    size:'small'
                                },
                                style:{
                                    marginRight:'10px',
                                    textAlign:'center'
                                },
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                              title: "温馨提示",
                                              content: "是否通过对当前用户的申请",
                                             onOk: () => {
                                                 this.deteleRow(params.row.familyId)
                                             }
                                        })
                                    },
                                },
                            },'删除')       
                         ]
                     );
                }
            }
          ],
          tableData:[],
          loading:false,
          page:1,
          total:0,
          pageSize:20,
          currPage:0,
          search:{
              address:'',
              name:''
          },
        // 新增家庭参数
        addModal:{
         status:false,
         tip:false
        },
        // 是否新增
        isEdit: false,
        // 存储组件城市编码
        cityOper: {
            city: "",
            region: "",
            stree: "",
            project: ""
        },
        // 存储完整的区码
        localStree:'',
        // 存储表单字段
        formData: {
            name: "",
            address: "",
            longitude: "",
            latitude: ""
        },
        isShow:true,
        // 存放修改的时候带的地址
        memberFromScope:'',
        familyId:'',
        detailModal:{
            status:false
        },
        // 查看详情的参数
        detailMessage:{},
        detailColumns:[
             {
                title: "成员名称",
                key: "userName",
                minWidth: 200,
                align: "center"
            },
             {
                title: "头像",
                key: "userName",
                minWidth: 200,
                align: "center",
                 render:(h,params)=>{
                return h('div',[
                    h('img',{
                    props:{

                    },
                    style:{
                        width:'30px',
                        height:'30px',
                        borderRadius:'50%',
                        textAlign:'center',
                        display:params.row.avatarUrl?'block' : 'none'
                    },
                    attrs:{
                        src:params.row.avatarUrl,
                    }
                    },

                    )
                ])
                }
            },
            {
                title: "性别",
                key: "sex",
                minWidth: 200,
                align: "center",
                render:(h,params)=>{
                    return h('div',{},params.row.sex == 0 ?'未知':params.row.sex == 1 ?'男':params.row.sex == 2 ?'女':'')
                }
            },
            {
                title: "手机号",
                key: "mobile",
                minWidth: 200,
                align: "center"
            },
            {
                title:'与对象关系',
                key:'relationship',
                minWidth:200,
                align:'center'
            },
            {
                title:'审核状态',
                minWidth:200,
                align:'center',
                render:(h,params) =>{
                    return h('div',{},params.row.status == 2?'已通过':params.row.status == 1?'待审核':'')
                }
            },
            {
                title:'操作',
                minWidth:200,
                align:'center',
                fixed:'right',
                render:(h,params) =>{
                    return h ('div',
                        {
                            style: {
                                textAlign: "left",
                                display:'flex',
                                justifyContent:'center'
                                },
                            },
                        [
                        h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                         this.$Modal.confirm({
                                              title: "温馨提示",
                                              content: "是否撤销当前用户的申请",
                                              onOk: () => {
                                                    this.options.map(item =>{
                                                        if(item.dictValue == params.row.relationship){
                                                            this.relationship = item.dictKey
                                                        }
                                                    })
                                                    let data = {
                                                        familyId:this.detailMessage.familyId,
                                                        status:0,
                                                        memberCustGlobalId:params.row.custGlobalId
                                                    }
                                                    this.updateFamily(data)
                                              }
                                         })  
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                    display:params.row.status == 1 ?'block':'none'
                                },
                            },
                            "撤销申请" //1001
                         ), 
                        h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                              title: "温馨提示",
                                              content: "是否通过对当前用户的申请",
                                              onOk: () => {
                                                    this.options.map(item =>{
                                                        if(item.dictValue == params.row.relationship){
                                                            this.relationship = item.dictKey
                                                        }
                                                    })
                                                    let data = {
                                                        familyId:this.detailMessage.familyId,
                                                        status:2,
                                                        memberCustGlobalId:params.row.custGlobalId
                                                    }
                                                    this.updateFamily(data)
                                              }
                                         })  
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                    display:params.row.status == 1 ?'block':'none'
                                },
                            },
                            "通过审核" //1001
                         ), 
                         h("Button",
                            {
                                attrs: {
                                type: "error",
                                size: "small",
                                },
                                 on: {
                                    click: () => {
                                            this.$Modal.confirm({
                                              title: "温馨提示",
                                              content: "是否移除当前用户",
                                              onOk: () => {
                                                    this.deleteFamily(params.row.custGlobalId)
                                              }
                                         })  
                                    },
                                },
                                style:{
                                    display:params.row.status == 2 ?'block':'none'
                                }
                            },
                            '移除成员'
           
                         )
                    ])
                }
            }

        ],
        isToken:'',
        options:[], //存放服务关系列表
        relationship:'',
        isTure:false
      }
    },
    //方法集合
    methods: {
        // 关闭查看详情模态框
        detailModalFn(status){
            this.detailModal.status = status
        },
        //关闭家庭模态框事件
        addModalFn(status) {
            if(!status && this.addModal.tip){
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.addModal.status = status;
                        this.addModal.tip = false
                        this.cityOper = {
                        city: "",
                        region: "",
                        stree: "",
                        project: ""
                        };
                        this.formData= {
                            name: "",
                            address: "",
                            longitude: "",
                            latitude: ""
                        },
                        this.isShow = false;
                        this.$nextTick(() => {
                            this.isShow = true;
                        });
                    }
                });
            }else{
                this.addModal.status = status;
            }
        
        },   
        // 新增家庭按钮
        addHome(){
            this.addModal.status = true;
            this.isEdit = false
            this.isShow = false;
            this.$nextTick(() => {
                this.isShow = true;
            });
        },
        // 模态框确认按钮
        addSave(){
           
            if(!this.isEdit){
                 // 如果是新增
                    if(this.formData.name ==''){
                        this.$Message.warning({
                            content:'家庭名称不能为空',
                            background:true
                        })
                        return
                    }
                    if(this.cityOper.city == '' || this.cityOper.region =='' || this.cityOper.stree =='' || this.cityOper.project == ''){
                        this.$Message.warning({
                            content:'家庭地址请选择完全',
                            background:true
                        })
                        return
                    }
                    if(this.formData.address == ''){
                        this.$Message.warning({
                            content:'家庭详细地址不能为空',
                            background:true
                        })
                        return
                    }
                let code = this.cityOper.project.split('-')
                let data = {
                    addrComplete:this.formData.address,
                    cityCode:code[1],//市
                    communityCode:code[4],//社区
                    countyCode:code[2],//区
                    familyId:'',
                    familyName:this.formData.name,
                    latitude:this.formData.latitude,
                    longitude:this.formData.longitude,
                    orgId: parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                    // orgId:11434,
                    provinceCode:code[0],//省
                    streetCode:code[3],//街道
                }
                this.addUpda(data)
            }else{
                if(this.formData.name == null || this.formData.name == '' ){
                        this.$Message.warning({
                            content:'家庭名称不能为空',
                            background:true
                        })
                        return
                    }
                    if(this.cityOper.city == '' || this.cityOper.region =='' || this.cityOper.stree =='' || this.cityOper.project == ''){
                        this.$Message.warning({
                            content:'家庭地址请选择完全',
                            background:true
                        })
                        return
                    }
                    if(this.formData.address == null || this.formData.address == ''){
                        this.$Message.warning({
                            content:'家庭详细地址不能为空',
                            background:true
                        })
                        return
                    }
                let code = this.cityOper.project.split('-')
                let data = {
                    addrComplete:this.formData.address,
                    cityCode:code[1],//市
                    communityCode:code[4],//社区
                    countyCode:code[2],//区
                    familyId:this.familyId,
                    familyName:this.formData.name,
                    latitude:this.formData.latitude,
                    longitude:this.formData.longitude,
                    orgId: parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                    // orgId: 11434,
                    provinceCode:code[0],//省
                    streetCode:code[3],//街道
                }
                this.addUpda(data)
            }
        },
        // 搜索
        searchBtn(){
            this.getList()
        },
        // 重置
        reset(){
            this.search.name = ''
            this.search.address = ''
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        hadlePageSize(val){
            this.page = val.page
            this.pageSize =val.pageSize
        },
        // 接口部分
        getList(){
            this.loading = true
            this.$get('/gateway/api/homecare/pc/OrgFamily/selectByOrgCodeAndNameOrAddr',{
                familyAddress:this.search.address,
                familyName:this.search.name,
                orgId: parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                // orgId:11434,
                page:this.page,
                pageSize:this.pageSize
            },
            {
                Authorization:this.isToken
            }).then(res=>{
                if(res.code == 200){
                     this.loading = false
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currPage = res.currentPage
                    this.pageSize = res.pageSize
                }else{
                    this.loading = false
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                     return
                }
                
            })
        },
        // 详情接口
        getDetail(data){
            this.$Message.destroy();
            this.$Message.loading({
                content: "正在加载信息，请稍等...",
                duration: 0,
                background:true
            });
            this.$get('/gateway/api/homecare/pc/OrgFamily/selectFamilyInfoByOrgCodeAndId',{
                familyId:data,
                orgId: parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                // orgId: 11434,
            },{
                Authorization:this.isToken
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.destroy();
                    let data = res.data
                    this.isEdit = true;
                    this.formData ={
                        name: data.familyName,
                        address: data.addrIncomplete,
                        longitude:data.longitude,
                        latitude: data.latitude
                    }
                    this.cityOper= {
                        city: data.cityCode,
                        region: data.countyCode,
                        stree: data.streetCode,
                        project: data.communityCode
                    },
                    this.addModal.status = true;
                     this.memberFromScope = data.provinceCode+'-'+data.cityCode+'-'+data.countyCode+'-'+data.streetCode+'-'+data.communityCode
                     this.memberFromScope = this.memberFromScope.toString()
                     
                }else{
                    this.$Message.destroy();
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                     return
                }
            })
        },
        getCheck(data){
            this.$Message.destroy();
            this.$Message.loading({
                content: "正在获取数据，请稍等...",
                duration: 0,
            });
            this.$get('/gateway/api/homecare/pc/OrgFamily/selectFamilyInfoByOrgCodeAndId',{
                familyId:data,
                orgId: parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                // orgId: 11434,
            },{
                Authorization:this.isToken
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.destroy();
                    if(this.isTure){
                        this.detailModal.status = true
                    }
                    let data = res.data
                    this.detailMessage = {}
                    this.detailMessage = data
                }else{
                  this.$Message.destroy();  
                  this.$Message.error({
                      content:res.msg,
                      background:true
                  })
                  return
                }
            })
        },
        // 修改、创建接口
        addUpda(data){
            this.$post('/gateway/api/homecare/pc/OrgFamily/createOrModifyFamily',{
                ...data
            },{"Content-Type": "application/json",Authorization:this.isToken}).then(res=>{
                if(res.code == 200){
                    if(!this.isEdit){
                        // 如果是新增
                        this.$Message.success({
                            content:'新增成功',
                            background:true
                        })
                    }else{
                        // 如果是修改
                        this.$Message.success({
                            content:'修改成功',
                            background:true
                        })
                    }
                    this.addModal.status =false
                    this.addModal.tip = false
                    this.isEdit = false
                    this.cityOper = {
                        city: "",
                        region: "",
                        stree: "",
                        project: ""
                    };
                    this.formData= {
                        name: "",
                        address: "",
                        longitude: "",
                        latitude: ""
                    },
                    this.cityOper= {
                        city: "",
                        region: "",
                        stree: "",
                        project: ""
                    },
                    this.isShow = false;
                    this.$nextTick(() => {
                        this.isShow = true;
                    });
                    this.page = this.currPage
                    this.getList()
                }else{
                    this.$Message.error({
                            content:res.msg,
                            background:true
                        })
                    return
                }
            })
        },
        // 审核接口
        updateFamily(data){
            this.$post('/gateway/api/homecare/pc/Family/updateFamilyMember',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                familyId:data.familyId,
                relationship:this.relationship,
                status:data.status,
                memberCustGlobalId:data.memberCustGlobalId
            },{Authorization:this.isToken}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'操作成功',
                        background:true
                    })
                    this.isTure = false
                    this.getCheck(this.detailMessage.familyId)
                    return
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 移除成员接口
        deleteFamily(data){
            this.$post('/gateway/api/homecare/pc/OrgFamily/deleteFamilyMember',{
                familyId:this.detailMessage.familyId ,
                custGlobalId:data
            },{"Content-Type": "application/x-www-form-urlencoded",Authorization:this.isToken}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'移除成功',
                        background:true
                    })
                    this.isTure = false
                    this.getCheck(this.detailMessage.familyId)
                    return
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        deteleRow(data){
            this.$post('/gateway/api/homecare/pc/OrgFamily/deleteFamily',{
                familyId:data
            },{"Content-Type": "application/x-www-form-urlencoded",Authorization:this.isToken}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.page = this.currPage
                    this.getList()
                    return               
                }else{
                    this.$Message.success({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        getOptions(){
            this.$get('/dictlist/lf-common/api/lfcommon/pc/dict/selectDictDataList',{
                dictType:'SERVICE_PERSON_TYPE',
                appCode:'HOMECARE'
            }).then(res=>{
                this.options = res.dataList
            })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.isToken = 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIxNzMyODY4MDAyNCIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2MjQ0NzExNDAsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiI1NDk5ZTA0ZC0yOGMyLTRhZmItODgyMS02Yzg3NTE5ODhhZTIiLCJjbGllbnRfaWQiOiJzdWlhbmp1IiwidG9rZW4iOiJzdWlhbmp1bG9naW5fdG9rZW5fZjllZTliYzg1OTBjNGYxNjk0NDU0MWQ3MTIwYzdiNzEifQ.PUsgEKyUWtH91AzLA6rVE5O4PGAfPCO6a5aV2q1JgJxY06r9n8kDtoQ1xjuAK-B4wTbwwqlW-eUr5hT3bPJnbc6sq9C4WwHzHqfPxvvcDx6jO1VwExjOidtyNJtS0nK8aOGMJkMn1RIzqIIcQOeACJNPoDYjsEHKaD6TGThzi4c'
        this.getList()
        this.getOptions()
    },
  }
</script>
<style lang='less' scoped>
  //编写css
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
 .equiment-title{
    position: relative;
    padding-left: 20px;
    margin:10px 0;
    &::after{
        position: absolute;
        content:'';
        top: 0;
        left: 0;
        width: 5px;
        height: 20px;
        background-color: #2db7f5;
    }
}
.table-div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;
    .item{
        width: 25%;
        padding: 10px;
        font-size: 16px;
        span{
            margin-left: 10px;
        }
    }
}
</style>